import {useLocation} from "react-router-dom";
import IdentInputForm from "./IdentInputForm";
import {useEffect, useState} from "react";
import axios from "axios";
import {CircularProgress} from "@material-ui/core";

export default function IdentificationMock( props ) {

    const search = useLocation().search;
    const caseId = new URLSearchParams(search).get('case_uuid');
    const [personData, setPersonData] = useState(null);



    useEffect(  () => {

        const fetchData = async () => {
            await axios.get( props.baseUrl + '/9c505b2e-4382-4d82-972b-e6f01f6f69bc/cases/' + caseId)
                .then( response => {
                    console.log(response.data);
                    setPersonData(response.data.personData);
                }).catch( fillMockData );
        }

        fetchData();

        } , [caseId, props.baseUrl]);

    const fillMockData = () => {
        const data = {
            givenName: '',
            middleName: '',
            familyName: '',
            dateOfBirth: '',
            addressData: {
                street: '',
                houseNumber: '',
                postalCode: '',
                city: ''
            },
            browserRedirect: ''
        }

        setPersonData(data);

    }

    if(personData) {
        return(
            <IdentInputForm caseId = { caseId } personData = { personData } baseUrl = { props.baseUrl } />
        );
    } else {
        return (
            <CircularProgress />
        );
    }



}
