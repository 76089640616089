import React from 'react';

export default function RedirectToProvider( props ) {


        const redirectToProvider = () => {
            if( props.redirectUrl ) {
                window.location.assign( props.redirectUrl );
                return null;
            } else {
                return (
                    <h1>No Redirect URL</h1>
                )
            }
        }

        return redirectToProvider();
}