import React from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

class BirthdayPicker extends React.Component {
    constructor(props) {
        super(props)

        this.handleDateChange = this.handleDateChange.bind(this);
    }

    handleDateChange(date) {
        this.props.onChange(date);
    }

    render() {
        return (
            <div>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
        <KeyboardDatePicker
          disableToolbar
          variant="outlined"
          format="dd/MM/yyyy"
          margin="normal"
          id="birthday"
          label="Birthdate"
          value = { this.props.date }
          onChange = { this.handleDateChange }
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        /></Grid>
        </MuiPickersUtilsProvider>
            </div>
        );
    }
}

export default BirthdayPicker;