import React from 'react';
import BirthdayPicker from './BirthdayPicker';
import RequiredTextField from './RequiredTextField'
import {Box, Button} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import IdentStatus from './IdentStatus';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import axios from 'axios';
import {withRouter} from "react-router-dom";
import RedirectToProvider from "./RedirectToProvider";
import moment from 'moment';

class IdentInputForm extends React.Component {

    constructor( props ) {
        super( props )
        this.state = {
            title: '',
            givenName: this.props.personData.givenName,
            middleName: this.props.personData.middleName,
            lastName: this.props.personData.familyName,
            birthdate: new Date(this.props.personData.dateOfBirth),
            street: this.props.personData.addressData.street,
            houseNumber: this.props.personData.addressData.houseNumber,
            postalCode: this.props.personData.addressData.postalCode,
            city: this.props.personData.addressData.city,
            identificationStatus: 'IDENTIFIED',
            errorMessageValidation: '',
            errorMessageApi: '',
            updateSuccessful: false,
            apiError: false,
            isLoading: true,
            redirectUrl: this.props.personData.browserRedirect,
            doRedirect: false
        };

        this.updateSuccessful = this.updateSuccessful.bind(this);
        this.updateNotSuccessful = this.updateNotSuccessful.bind(this);
        this.redirectToProvider = this.redirectToProvider.bind(this);

    }

    postData = () => {

        this.clearErrorMessage();

        if( this.validateInput() ) {
            console.log ( this.state );
            this.asyncCallNectBackend();
        }
    }

    convertDate (date) {
        const newDate = new Date().setUTCFullYear(date.getFullYear(), date.getMonth(), date.getDate())
        return moment(new Date(newDate)).format('YYYY-MM-DD')
    }
    async asyncCallNectBackend() {
        try {
            const payload={
                "givenName": this.state.givenName,
                "nameSuffix": this.state.middleName,
                "surname": this.state.lastName,
                "title": this.state.title,
                "birthdate": this.convertDate(this.state.birthdate),
                "street": this.state.street,
                "houseNumber": this.state.houseNumber,
                "postalCode": this.state.postalCode,
                "city": this.state.city,
                "caseId": this.props.caseId,
                "identificationState": this.state.identificationStatus
            }

            axios.post(this.props.baseUrl + '/personData', payload, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                },
            }).then(response => {
                if( response.status === 200) {
                    this.updateSuccessful();
                    this.redirectToProvider();
                }
            }).catch(error => {
                if( error.response ) {
                    this.updateNotSuccessful( error.response.data );
                }
            })
        } catch(error) {
            console.log("error", error);
        }
    }

    redirectToProvider = () => {
        this.setState( { doRedirect: true });
    }

    validateInput = () => {
        
        if( !this.validateRequiredText( 'Given Name', this.state.givenName )) {
            return false;
        } else if ( !this.validateRequiredText( 'Last Name', this.state.lastName ) ) {
            return false;
        } else if ( !this.validateTextFormat( 'Middle Name', this.state.middleName ) ) {
            return false;
        } else if ( !this.validateNumberFormat( 'Postalcode', this.state.postalCode ) ) {
            return false;
        } else if ( !this.validateTextFormat( 'City', this.state.city ) ) {
            return false;
        }

        return true;
    }
    
    validateRequiredText = ( fieldName, textInput ) => {
        if( !textInput.length ) {
            this.setState ( { errorMessageValidation: 'Required Field missing: "' + fieldName + '"' } )
            return false;
        }

        return this.validateTextFormat( fieldName, textInput )
    }

    validateTextFormat = ( fieldName, textInput ) => {

        var regEx = new RegExp("^$|^[a-zA-ZäüöÄÜÖ'][a-zA-Z-äüöÄÜÖ' ]+[a-zA-ZäüöÄÜÖ']?$")
        if( !regEx.test( textInput ) ) {
            this.setState ( { errorMessageValidation: 'Wrong Character in "' + fieldName + '"' } )
            return false;
        }

        return true;
    }

    validateNumberFormat = ( fieldName, textInput ) => {

        var regEx = new RegExp("^$|^[0-9]{1,5}$")
        if( !regEx.test( textInput ) ) {
            this.setState ( { errorMessageValidation: 'Wrong Numberformat for "' + fieldName + '" max. 5 digits' } )
            return false;
        }

        return true;
    }

    updateSuccessful = () => {
        this.setState ( { updateSuccessful: true } )
    }

    updateNotSuccessful = ( errorMessage ) => {
        this.setState ( { errorMessageApi: errorMessage } )
        this.setState ( { apiError: true } )
    }

      handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        this.setState ( { updateSuccessful: false } );
        this.setState ( { apiError: false } );
      };

    clearErrorMessage = () => {
        this.setState ( { errorMessageValidation: '' } )
        this.setState ( { errorMessageApi: '' } )
        this.setState( { apiError: false } )
    }

    render() {
        if( this.state.doRedirect ) {
            return (
              <RedirectToProvider redirectUrl = { this.state.redirectUrl } />
            );
        } else {
            return (
                <div>
                    <div>
                        <h2>
                            <label htmlFor="title">CaseID: { this.props.caseId } </label>
                        </h2>
                    </div>
                    <Container maxWidth="lg">
                        <Box>
                            <div style={{textAlign: "left", fontWeight: "bold"}}>Person Data</div>
                            <Grid container style={{marginTop: "10px"}} spacing = { 1 } justify = "flex-start">
                                <Grid item >
                                    <TextField id = 'title' size = "small" label = "Title" variant = "outlined" defaultValue = { this.state.title } onChange = { this.textFieldCallback }/>
                                </Grid>
                            </Grid>
                            <Grid container style={{marginTop: "10px"}} spacing = { 1 } justify = "flex-start">
                                <Grid item >
                                    <RequiredTextField id = 'givenName' label = "Given Name" defaultValue = { this.state.givenName } onChange = { this.textFieldCallback.bind( this ) }/>
                                </Grid>
                                <Grid item >
                                    <TextField id = 'middleName' size = "small" label = "Middle Name" variant = "outlined" defaultValue = { this.state.middleName } onChange = { this.textFieldCallback }/>
                                </Grid>
                                <Grid item >
                                    <RequiredTextField id = 'lastName' label = "Last Name" defaultValue = { this.state.lastName } onChange = { this.textFieldCallback.bind( this ) } />
                                </Grid>
                                <Grid container style={{ marginLeft: "3px"}} spacing = { 1 } justify = "flex-start">
                                    <Grid item><BirthdayPicker date = { this.state.birthdate } onChange = { this.birthdateCallback.bind( this ) }/></Grid>
                                </Grid>
                            </Grid>
                        </Box>

                    </Container>
                    <Container>

                        <Box>
                            <div style={{textAlign: "left", marginTop: "15px", fontWeight: "bold"}}> Address Data</div>
                            <Grid container style={{marginTop: "10px"}} spacing = { 1 } justify = "flex-start">
                                <Grid item >
                                    <TextField id = 'street' size = "small" label = "Street" variant = "outlined" defaultValue = { this.state.street } onChange = { this.textFieldCallback }/>
                                </Grid>
                                <Grid item >
                                    <TextField id = 'houseNumber' size = "small" label = "Housenumber" variant = "outlined" defaultValue = { this.state.houseNumber } onChange = { this.textFieldCallback }/>
                                </Grid>
                            </Grid>
                            <Grid container style={{marginTop: "10px"}} spacing = { 1 } justify = "flex-start">
                                <Grid item >
                                    <TextField id = 'postalCode' size = "small" label = "Postal Code" variant = "outlined" defaultValue = { this.state.postalCode } onChange = { this.textFieldCallback }/>
                                </Grid>
                                <Grid item >
                                    <TextField id = 'city' size = "small" label = "City" variant = "outlined" defaultValue = { this.state.city } onChange = { this.textFieldCallback }/>
                                </Grid>
                            </Grid>
                        </Box>

                    </Container>
                    <Container maxWidth="lg">

                        <Box>
                            <div style={{textAlign: "left", marginTop: "15px", fontWeight: "bold"}}>Identification Status</div>
                            <Grid container spacing = { 1 } justify = "flex-start">
                                <Grid item ><IdentStatus id = 'identificationStatus' onChange = { this.textFieldCallback.bind( this ) } /></Grid>
                            </Grid>
                        </Box>

                    </Container>
                    <Grid container spacing = { 1 }>
                        <Grid item xs={12}><Button variant="contained" color="primary" onClick= { this.postData }>Finish Identification</Button></Grid>
                        <Grid item xs={12}>{ this.state.errorMessageValidation && <Alert severity="error"> { this.state.errorMessageValidation } </Alert> }</Grid>
                        <Grid item xs={12}>
                            { this.state.updateSuccessful && <Snackbar open={ this.state.updateSuccessful } autoHideDuration={6000} onClose={ this.handleClose }>
                                <Alert onClose={ this.handleClose } severity="success">Case was successful finished</Alert></Snackbar>}
                            { this.state.apiError && <Snackbar open={ this.state.apiError } autoHideDuration={6000} onClose={ this.handleClose }>
                                <Alert onClose={ this.handleClose } severity="error"> { this.state.errorMessageApi } </Alert></Snackbar>}
                        </Grid>
                    </Grid>
                </div>
            );
        }
    }

    birthdateCallback = (date ) => {
        this.setState( { birthdate: date } )
    }
    
    textFieldCallback = ( event ) => {
        const {id , value} = event.target
        this.setState( { [id]: value } )
    }

}

export default withRouter(IdentInputForm);
