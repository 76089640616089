import React from 'react';
import { Box, Container } from '@material-ui/core';

function Header() {
    return(
        <Container maxWidth="lg">
            <Box  style = { { backgroundColor: "#00008B", color: "White" } }>
                <h1>Nect Identification Mock</h1>
        </Box>
        </Container>
    )
}
export default Header;