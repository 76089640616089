import './App.css';
import IdentificationMock from './components/IdentificationMock';
import {BrowserRouter as Router, Route, Switch,} from "react-router-dom";
import Header from './components/Header/Header';

function App() {

    const prepareBaseUrl = () => {
        let urlToPrepare = window.location.href;
        if(urlToPrepare.includes(':3000')) {
            urlToPrepare = urlToPrepare.split(':3000')[0] + '';
        } else {
            urlToPrepare = urlToPrepare.split('?')[0];
        }
        return urlToPrepare;
    }

  return (
    <div className="App">
      <Router>
                <Header/>
                <Switch>
                    <Route exact path="" component={ () => <IdentificationMock  baseUrl = { prepareBaseUrl() } /> }/>
                </Switch>
            </Router>
    </div>
  );
}

export default App;
