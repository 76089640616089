import React from 'react';
import TextField from '@material-ui/core/TextField';

class IdentStatus extends React.Component {

    constructor ( props ) {
        super ( props )

        this.handleStatusChange = this.handleStatusChange.bind( this );
    }

    identStates = [
        {
          value: 'IDENTIFIED',
          label: 'Identifiziert',
        },
        {
          value: 'NOT_IDENTIFIED',
          label: 'Nicht identifiziert',
        },
        {
            value: 'NOT_IDENTIFIED_FRAUD_ATTEMPT',
            label: 'Betrugsversuch',
        }
      ]

      handleStatusChange ( event ) {
        this.props.onChange ( event );
      }

      render () {
          return (
            <TextField id = { this.props.id }
          select
          size = "small"
          label= { this.props.label }
          value= { this.props.value }
          onChange= { this.handleStatusChange }
          SelectProps={{
            native: true,
          }}
          variant="outlined">
          { this.identStates.map(( option ) => (
            <option key = { option.value } value = { option.value }>
              { option.label }
            </option>
          ))}
          </TextField>
          );
      }

}

export default IdentStatus;