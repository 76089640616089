import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const ValidationTextField = withStyles ({
    root: {
      '& input:valid + fieldset': {
        borderColor: 'green',
        borderWidth: 2,
      },
      '& input:invalid + fieldset': {
        borderColor: 'red',
        borderWidth: 2,
      },
      '& input:valid:focus + fieldset': {
        borderLeftWidth: 6,
        padding: '4px !important', // override inline-style
      },
    },
  })( TextField );

class RequiredTextField extends React.Component {
    
    constructor ( props ) {
        super ( props )
        
        this.handleChange = this.handleChange.bind ( this )

    }

    handleChange( event ) {
        this.props.onChange( event )
    }

    render () {
        return (
            <ValidationTextField
            size = "small"
            required
            id = { this.props.id }
            label = { this.props.label }
            variant = "outlined"
            defaultValue = { this.props.defaultValue }
            onChange = { this.handleChange }
          />
        );
    }
}

export default RequiredTextField;